<template>
    
    <div>
        <workers-import-file
            :form="form_id"
            :tariff_id="tariff_id"
            :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
            @refetch-data="refetchData"
        />
        
        <template v-if="ct_isLoaded">
            <CounterTime 
                :formData="formData"
                :ct_deadline="ct_deadline"
                class="mb-1"
            />   
        </template>

        <b-row v-if="earrings.length">
            <b-col cols="12">
                <b-alert show variant="danger">
                    <h4 class="alert-heading">
                        <b-row align-v="center" align-h="between">
                            <b-col>LISTADO DE PENDIENTES DEL FORMULARIO ({{ previousFormDate }})</b-col>
                            <b-col cols="auto">
                                <ButtonUpload
                                    :code="codeArr[16]"
                                    :getQuestionFiles="getQuestionFiles"
                                />
                            </b-col>
                        </b-row>
                    </h4>
                    <div class="alert-body">

                        <div v-for="(item, index) in earrings" :key="index">
                            <span><strong>Pregunta ({{ item.number }})</strong>: {{ item.question }}</span> <br>
                            <span><strong>Observaciones</strong>:</span>
                            <div style="white-space: pre-line;" v-html="item.comments"></div>
                            
                            <hr v-if="(index + 1) !== earrings.length">
                        </div>

                    </div>
                </b-alert>
            </b-col>
        </b-row>
                
        <b-row>
            <b-col :cols="tariff_id ? '9': '12'">
                <b-button       
                    @click="viewWorkers()"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"	
                    variant="primary"	
                    size="lg"			
                    block
                > 
                    <feather-icon size="1x" icon="FileTextIcon" /> Planilla         
                </b-button> 
            </b-col>
            <b-col cols="3" v-if="tariff_id">                
                <ButtonUpload 
                    :code="codeArr[14]" 
                    :getQuestionFiles="getQuestionFiles" 
                    :isFromPlanilla="true" 
                /> 
            </b-col>
        </b-row>        
        
        <b-modal
            ref="refPlantillaModal"
            size="xl"
            centered
            title-tag="div"
            header-bg-variant="primary"
            header-text-variant="white"
            title="PLANILLA DE EMPLEADOS CARGADOS"
            no-close-on-backdrop
            ok-only
            ok-title="ACEPTAR"
        >
            <b-alert
                variant="info"
                show
            >
                <div class="alert-body">
                    <ul class="mb-0">
                       <!--  <li>
                            Los datos han sido cargados con respecto al mes anterior. Actualice la lista si lo requiere.                                
                        </li> -->
                        <li>
                            Cargar el 100% de sus colaboradores que se encuentren en su planilla.
                        </li>
                        <li>
                            En caso se produzca algún error en la carga masiva. Se enviará un correo con los detalles, por favor revisar bandeja de entrada o de spam.
                        </li>
                    </ul>
                </div>
            </b-alert>
            
            <b-card-text class="my-2">
                
                <!-- sorting  -->
                <b-form-group
                    label="Ordenar"
                    label-size="sm"
                    label-align-sm="left"
                    label-cols-sm="2"
                    label-for="sortBySelect"
                    class="mr-1 mb-md-0"
                >
                    <b-input-group
                        size="sm"
                    >
                        <b-form-select
                            id="sortBySelect"
                            v-model="sortBy"
                            :options="sortOptions"
                        >
                            <template #first>
                                <option value="">
                                    Ninguno
                                </option>
                            </template>
                        </b-form-select>
                        <b-form-select
                            v-model="sortDesc"
                            size="sm"
                            :disabled="!sortBy"
                        >
                            <option :value="false">
                                Asc
                            </option>
                            <option :value="true">
                                Desc
                            </option>
                        </b-form-select>
                    </b-input-group>
                </b-form-group>

                <!-- filter -->
                <b-form-group
                    label="Fitrar"
                    label-cols-sm="2"
                    label-align-sm="left"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0 mt-1"
                >
                    <b-input-group size="sm">
                        <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            placeholder="Buscar ..."
                        />
                        <b-input-group-append>
                            <b-button
                                :disabled="!filter"
                                @click="filter = ''"
                            >
                                Limpiar
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <b-row align-v="center" align-h="between">

                    <b-col
                        cols="3"
                        class="my-1"
                    >
                        <b-form-group
                            class="mb-0"
                        >
                            <label class="d-inline-block text-sm-left mr-50">Por página</label>
                            <b-form-select
                                id="perPageSelect"
                                v-model="perPage"
                                size="sm"
                                :options="pageOptions"
                                class="w-50"
                            />
                        </b-form-group>
                    </b-col>
                    
                    <b-col
                        v-if="[States.EN_CURSO, States.POR_REALIZAR].includes(stateId)"
                        cols="9"
                        class="my-1 text-right"
                    >
                        <div class="mr-2" style="display: inline-block; width: 20%;">
                            <json-excel :data="downloadWorkers()" name="planilla.xls"></json-excel>
                        </div>
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="success"
                            class="mr-2"
                            size="sm"
                            @click="openSidebar()"
                        >
                            CARGA MASIVA 
                        </b-button>
                        <b-button
                            id="toggle-btn"
                            size="sm"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            @click="addWorker()"
                            variant="outline-primary"
                        >
                            AGREGAR
                        </b-button>
                    </b-col>

                </b-row>

                <b-table
                    ref="workersTable"
                    class="position-relative"
                    :sticky-header="stickyHeader"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="workers"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    striped
                    hover
                    responsive
                    show-empty
                    empty-text="No se encontraron registros coincidentes"
                >
                    <template #cell(id)="data">
                        <span class="text-nowrap">
                            {{ data.index+1 }}
                        </span>
                    </template>

                    <template #cell(worker_state)="data">
                        <span class="text-nowrap" v-if="data.item.worker_state">
                            <b-badge
                                :id="`state-row-${data.item.id}`" 
                                :variant="`light-${ data.item.worker_state.color}`"
                            >
                                {{ data.item.worker_state.name }}
                            </b-badge>
                            <b-tooltip
                                v-if="data.item.worker_state.name === 'CESADO'"
                                :id="`tooltip-row-${data.item.id}`"
                                :target="`state-row-${data.item.id}`"
                                placement="left"
                            >
                                <p class="mb-0">
                                    Motivo
                                </p>
                                <p class="mb-0">
                                    {{ data.item.termination_reason.name }}
                                </p>
                            </b-tooltip>
                        </span>
                    </template>
                    
                    <template #cell(tariff_compliance)="data">
                        <span class="text-nowrap">
                            <b-button
                                :id="`tariff-row-${data.item.id}`"
                                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                :variant="`flat-${data.item.tariff_compliance == 1 ? 'success' : 'warning'}`"
                                class="btn-icon rounded-circle"
                            >
                                <feather-icon 
                                    size="1.5x"
                                    :icon="data.item.tariff_compliance == 1 ? 'CheckIcon': 'InfoIcon'"                                
                                /> 
                            </b-button>
                            <b-tooltip
                                :id="`tooltip-tariff-row-${data.item.id}`"
                                :target="`tariff-row-${data.item.id}`"
                                placement="left"
                            >
                                <p class="mb-0">
                                    {{ data.item.tariff_compliance == 1 ? 'CUMPLE': 'ADJUNTAR BOLETA' }}
                                </p>
                            </b-tooltip>
                        </span>
                    </template>

                    <template #cell(actions)="data">
                        <b-dropdown
                            variant="link"
                            no-caret
                            :right="$store.state.appConfig.isRTL">

                            <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                            </template>

                            <b-dropdown-item @click="editWorker(data.item, data.index)">
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50">Editar</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="deleteWorker(data.item.id)">
                                <feather-icon icon="TrashIcon" />
                                <span class="align-middle ml-50">Eliminar</span>
                            </b-dropdown-item>
                        </b-dropdown>                
                    </template> 
                </b-table>

                <!-- pagination -->
                <div>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>
                </div>

            </b-card-text>
        </b-modal>
        
        <b-modal
            id="idWorkerModal"
            ref="refWorkerModal"
            title-tag="div"
            header-bg-variant="primary"
            header-text-variant="white"            
            :title="titleModal"
            @show="resetModal"
            @hidden="resetModal"            
            no-close-on-backdrop
            centered
            hide-footer
            size="lg"
        >
            <template #default="{ hide }">
                <validation-observer
                    #default="{ handleSubmit }"
                    ref="workerForm"
                >            
                    <b-form
                        @submit.prevent="handleSubmit(onSubmit)"
                        @reset.prevent="resetModal"                    
                    >
                        <b-row>
                            <b-col cols='12' md="8">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Nombre Completo"
                                    rules="required"
                                >
                                    <b-form-group
                                        label="Nombre completo"
                                        label-for="fullName"
                                    >
                                        <b-form-input
                                            id="fullName"
                                            v-model="dataWorker.fullName"
                                            type="text"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' md="4">
                                <validation-provider
                                    #default="{ errors }"
                                    name="DNI"
                                    rules="required|digits:8"
                                >
                                    <b-form-group
                                        label="DNI"
                                        label-for="dni"
                                    >
                                        <b-form-input
                                            id="dni"
                                            v-model="dataWorker.dni"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' md="8">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Puesto/Cargo"
                                    rules="required"
                                >
                                    <b-form-group
                                        label="Puesto/Cargo"
                                        label-for="roleInput"
                                    >               
                                        <v-select
                                            v-if="tariff_id"
                                            id="roleSelect"
                                            placeholder="Seleccione"
                                            v-model="dataWorker.role"
                                            :options="optionsPositons"
                                            @input="changePosition($event); validateRequiredFields();"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <b-form-input
                                            v-else
                                            id="roleInput"
                                            v-model="dataWorker.role"
                                            @input="validateRequiredFields"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            
                            <b-col cols='12' md="4">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Categoría"
                                    rules="required"
                                >
                                    <b-form-group
                                        label="Categoría"
                                        label-for="categorySelect"
                                    >                    
                                        <v-select
                                            id="categorySelect"
                                            placeholder="Seleccione"
                                            v-model="dataWorker.category"                        
                                            :options="optionsCategory"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col v-if="tariff_id" cols='12' md="4">
                                <b-form-group
                                    label="Sueldo Tarifario"
                                    label-for="salaryTariffInput"
                                >
                                    <b-form-input
                                        id="salaryTariffInput"
                                        v-model="salaryTariff"
                                        disabled
                                        readonly
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col cols='12' :md="tariff_id ? '4' : '6'">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Sueldo"
                                    rules="required|positive"
                                >
                                    <b-form-group
                                        label="Sueldo"
                                        label-for="salaryInput"
                                    >
                                        <b-form-input
                                            id="salaryInput"
                                            type="number"
                                            step=".01"
                                            v-model="dataWorker.salary"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' :md="tariff_id ? '4' : '6'">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Jornal Diario"
                                    rules="required|positive"
                                >
                                    <b-form-group
                                        label="Jornal Diario"
                                        label-for="dailyWageInput"
                                    >
                                        <b-form-input
                                            id="dailyWageInput"
                                            type="number"
                                            step=".01"
                                            v-model="dataWorker.daily_wage"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' md="8">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Correo"
                                    rules="email"
                                >
                                    <b-form-group
                                        label="Correo electrónico"
                                        label-for="emailInput"
                                    >
                                        <b-form-input
                                            id="emailInput"
                                            v-model="dataWorker.email"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' md="4">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Celular"
                                    rules="alpha-num"
                                >
                                    <b-form-group
                                        label="Celular"
                                        label-for="phoneInput"
                                    >
                                        <b-form-input
                                            id="phoneInput"
                                            v-model="dataWorker.phone"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' md="4">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Estado"
                                    rules="required"
                                >
                                    <b-form-group
                                        label="Estado"
                                        label-for="workerStateSelect"
                                    >                    
                                        <v-select
                                            id="workerStateSelect"
                                            placeholder="Seleccione"
                                            v-model="dataWorker.worker_state"                        
                                            :options="optionsWorkerStates"
                                            label="name"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' md="8">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Motivo de Cese"
                                >
                                    <b-form-group
                                        v-if="dataWorker.worker_state && dataWorker.worker_state.name === 'CESADO'"
                                        label="Motivo de Cese"
                                        label-for="terminationReasonSelect"
                                    >                    
                                        <v-select
                                            id="terminationReasonSelect"
                                            placeholder="Seleccione"
                                            v-model="dataWorker.termination_reason"
                                            :options="optionsTerminationReasons"
                                            label="name"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                        </b-row>
                        
                        <hr>
                        <div class="d-flex justify-content-end">                        
                            <b-button
                                v-if="!isLoading"
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                variant="outline-secondary"
                                class="mr-2"
                                type="button"
                                @click="hide"
                            >
                                CANCELAR
                            </b-button>
                            
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                type="submit"
                                :disabled="isLoading || isSubmitDisabled"
                            >
                                <div v-if="isLoading">
                                    <b-spinner small/>
                                    Cargando..
                                </div>
                                <span v-else>{{ okTitle }}</span>
                            </b-button>
                        </div>
                        
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        
        <b-form>        
            <table class="content-table table table-bordered text-center w-100 ">
                <thead>
                    <tr>
                        <th colspan="12">CUMPLIMIENTO MENSUAL DE LAS OBLIGACIONES LABORALES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><label>1</label></td>
                        <td colspan="3">
                            <label class="form_question">Pago Oportuno de las Remuneraciones</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__1__yes" v-model="questionsArr[0]" class="custom-control-input" v-bind:value="1" >
                                <label class="custom-control-label" for="question__1__yes">SÍ</label> 
                             </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__1__parcial" v-model="questionsArr[0]" class="custom-control-input" v-bind:value="2" >
                                <label class="custom-control-label" for="question__1__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__1__not" v-model="questionsArr[0]" class="custom-control-input" v-bind:value="3"  >
                                <label class="custom-control-label" for="question__1__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__1__na" v-model="questionsArr[0]" class="custom-control-input" v-bind:value="4" >
                                <label class="custom-control-label" for="question__1__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[0]" :getQuestionFiles="getQuestionFiles" /> 
                            </div>                        
                        </td>
                    </tr>
                    <tr>
                        <td><label>2</label></td>
                        <td colspan="3">
                            <label class="form_question">Descanso Anual Remunerado</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__2__yes" v-model="questionsArr[1]" class="custom-control-input" value="1" >
                                <label class="custom-control-label" for="question__2__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__2__parcial" v-model="questionsArr[1]" class="custom-control-input" value="2" >
                                <label class="custom-control-label" for="question__2__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__2__not" v-model="questionsArr[1]" class="custom-control-input" value="3" >
                                <label class="custom-control-label" for="question__2__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__2__na" v-model="questionsArr[1]" class="custom-control-input" value="4" >
                                <label class="custom-control-label" for="question__2__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[1]" :getQuestionFiles="getQuestionFiles" /> 
                            </div>                        
                        </td>
                    </tr>
                    <tr>
                        <td><label>3</label></td>
                        <td colspan="3">
                            <label class="form_question">Sistema Nacional Pensiones (SNP)</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__3__yes" v-model="questionsArr[2]" class="custom-control-input" value="1" >
                                <label class="custom-control-label" for="question__3__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__3__parcial" v-model="questionsArr[2]" class="custom-control-input" value="2" >
                                <label class="custom-control-label" for="question__3__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__3__not" v-model="questionsArr[2]" class="custom-control-input" value="3" >
                                <label class="custom-control-label" for="question__3__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__3__na" v-model="questionsArr[2]" class="custom-control-input" value="4" >
                                <label class="custom-control-label" for="question__3__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[2]" :getQuestionFiles="getQuestionFiles" /> 
                            </div>                        
                        </td>
                    </tr>
                    <tr>
                        <td><label>4</label></td>
                        <td colspan="3">
                            <label class="form_question">EsSalud</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__4__yes" v-model="questionsArr[3]" class="custom-control-input" value="1" >
                                <label class="custom-control-label" for="question__4__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__4__parcial" v-model="questionsArr[3]" class="custom-control-input" value="2" >
                                <label class="custom-control-label" for="question__4__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__4__not" v-model="questionsArr[3]" class="custom-control-input" value="3" >
                                <label class="custom-control-label" for="question__4__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__4__na" v-model="questionsArr[3]" class="custom-control-input" value="4" >
                                <label class="custom-control-label" for="question__4__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[3]" :getQuestionFiles="getQuestionFiles" /> 
                            </div>                        
                        </td>
                    </tr>
                    <tr>
                        <td><label>5</label></td>
                        <td colspan="3">
                            <label class="form_question">Fcjmms</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__5__yes" v-model="questionsArr[4]" class="custom-control-input" value="1" >
                                <label class="custom-control-label" for="question__5__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__5__parcial" v-model="questionsArr[4]" class="custom-control-input" value="2" >
                                <label class="custom-control-label" for="question__5__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__5__not" v-model="questionsArr[4]" class="custom-control-input" value="3" >
                                <label class="custom-control-label" for="question__5__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__5__na" v-model="questionsArr[4]" class="custom-control-input" value="4" >
                                <label class="custom-control-label" for="question__5__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[4]" :getQuestionFiles="getQuestionFiles" /> 
                            </div>                        
                        </td>
                    </tr>
                    <tr>
                        <td><label>6</label></td>
                        <td colspan="3">
                            <label class="form_question">5ta Categoría</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__6__yes" v-model="questionsArr[5]" class="custom-control-input" value="1" >
                                <label class="custom-control-label" for="question__6__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__6__parcial" v-model="questionsArr[5]" class="custom-control-input" value="2" >
                                <label class="custom-control-label" for="question__6__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__6__not" v-model="questionsArr[5]" class="custom-control-input" value="3" >
                                <label class="custom-control-label" for="question__6__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__6__na" v-model="questionsArr[5]" class="custom-control-input" value="4" >
                                <label class="custom-control-label" for="question__6__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[5]" :getQuestionFiles="getQuestionFiles" /> 
                            </div>                        
                        </td>
                    </tr>
                    <tr>
                        <td><label>7</label></td>
                        <td colspan="3">
                            <label class="form_question">Sistema Privado de Pensiones (AFP)</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__7__yes" v-model="questionsArr[6]" class="custom-control-input" value="1" >
                                <label class="custom-control-label" for="question__7__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__7__parcial" v-model="questionsArr[6]" class="custom-control-input" value="2" >
                                <label class="custom-control-label" for="question__7__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__7__not" v-model="questionsArr[6]" class="custom-control-input" value="3" >
                                <label class="custom-control-label" for="question__7__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__7__na" v-model="questionsArr[6]" class="custom-control-input" value="4" >
                                <label class="custom-control-label" for="question__7__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[6]" :getQuestionFiles="getQuestionFiles" />   
                            </div>                        
                        </td>
                    </tr>
                    <tr>
                        <td><label>8</label></td>
                        <td colspan="3">
                            <label class="form_question">Seguro SCTR (Salud y Pensión)</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__8__yes" v-model="questionsArr[7]" class="custom-control-input" value="1" >
                                <label class="custom-control-label" for="question__8__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__8__parcial" v-model="questionsArr[7]" class="custom-control-input" value="2" >
                                <label class="custom-control-label" for="question__8__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__8__not" v-model="questionsArr[7]" class="custom-control-input" value="3" >
                                <label class="custom-control-label" for="question__8__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__8__na" v-model="questionsArr[7]" class="custom-control-input" value="4" >
                                <label class="custom-control-label" for="question__8__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[7]" :getQuestionFiles="getQuestionFiles" /> 
                            </div>                        
                        </td>
                    </tr>
                    <tr>
                        <td><label>9</label></td>
                        <td colspan="3">
                            <label class="form_question">Seguro Vida Ley (Empleados y Obreros)</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__9__yes" v-model="questionsArr[8]" class="custom-control-input" value="1" >
                                <label class="custom-control-label" for="question__9__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__9__parcial" v-model="questionsArr[8]" class="custom-control-input" value="2" >
                                <label class="custom-control-label" for="question__9__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__9__not" v-model="questionsArr[8]" class="custom-control-input" value="3" >
                                <label class="custom-control-label" for="question__9__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__9__na" v-model="questionsArr[8]" class="custom-control-input" value="4" >
                                <label class="custom-control-label" for="question__9__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[8]" :getQuestionFiles="getQuestionFiles" /> 
                            </div>                        
                        </td>
                    </tr>
                    <tr>
                        <td><label>10</label></td>
                        <td colspan="3">
                            <label class="form_question">Liquidación de Beneficios Sociales</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__10__yes" v-model="questionsArr[9]" class="custom-control-input" value="1" >
                                <label class="custom-control-label" for="question__10__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__10__parcial" v-model="questionsArr[9]" class="custom-control-input" value="2" >
                                <label class="custom-control-label" for="question__10__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__10__not" v-model="questionsArr[9]" class="custom-control-input" value="3" >
                                <label class="custom-control-label" for="question__10__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__10__na" v-model="questionsArr[9]" class="custom-control-input" value="4" >
                                <label class="custom-control-label" for="question__10__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[9]" :getQuestionFiles="getQuestionFiles" />   
                            </div>                        
                        </td>
                    </tr>
                    <tr>
                        <td><label>11</label></td>
                        <td colspan="3">
                            <label class="form_question">Examen Médico Ocupacional de Salida (EMOS)</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__11__yes" v-model="questionsArr[10]" class="custom-control-input" value="1" >
                                <label class="custom-control-label" for="question__11__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__11__parcial" v-model="questionsArr[10]" class="custom-control-input" value="2" >
                                <label class="custom-control-label" for="question__11__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__11__not" v-model="questionsArr[10]" class="custom-control-input" value="3" >
                                <label class="custom-control-label" for="question__11__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__11__na" v-model="questionsArr[10]" class="custom-control-input" value="4" >
                                <label class="custom-control-label" for="question__11__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[10]" :getQuestionFiles="getQuestionFiles" /> 
                            </div>                        
                        </td>
                    </tr>
                    <tr>
                        <td><label>12</label></td>
                        <td colspan="3">
                            <label class="form_question">Compensación de Tiempo de Servicios</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__12__yes" v-model="questionsArr[11]" class="custom-control-input" value="1" >
                                <label class="custom-control-label" for="question__12__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__12__parcial" v-model="questionsArr[11]" class="custom-control-input" value="2" >
                                <label class="custom-control-label" for="question__12__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__12__not" v-model="questionsArr[11]" class="custom-control-input" value="3" >
                                <label class="custom-control-label" for="question__12__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__12__na" v-model="questionsArr[11]" class="custom-control-input" value="4" >
                                <label class="custom-control-label" for="question__12__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[11]" :getQuestionFiles="getQuestionFiles" />  
                            </div>                        
                        </td>
                    </tr>
                    <tr>
                        <td><label>13</label></td>
                        <td colspan="3">
                            <label class="form_question">Gratificaciones Ordinarias</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__13__yes" v-model="questionsArr[12]" class="custom-control-input" value="1" >
                                <label class="custom-control-label" for="question__13__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__13__parcial" v-model="questionsArr[12]" class="custom-control-input" value="2" >
                                <label class="custom-control-label" for="question__13__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__13__not" v-model="questionsArr[12]" class="custom-control-input" value="3" >
                                <label class="custom-control-label" for="question__13__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__13__na" v-model="questionsArr[12]" class="custom-control-input" value="4" >
                                <label class="custom-control-label" for="question__13__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[12]" :getQuestionFiles="getQuestionFiles" /> 
                            </div>                        
                        </td>
                    </tr>
                    <tr>
                        <td><label>14</label></td>
                        <td colspan="3">
                            <label class="form_question">Participación en las Utilidades</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__14__yes" v-model="questionsArr[13]" class="custom-control-input" value="1" :checked="questionsArr[13]=='1' ? true : false">
                                <label class="custom-control-label" for="question__14__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__14__parcial" v-model="questionsArr[13]" class="custom-control-input" value="2" :checked="questionsArr[13]=='2' ? true : false">
                                <label class="custom-control-label" for="question__14__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__14__not" v-model="questionsArr[13]" class="custom-control-input" value="3" :checked="questionsArr[13]=='3' ? true : false">
                                <label class="custom-control-label" for="question__14__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__14__na" v-model="questionsArr[13]" class="custom-control-input" value="4" :checked="questionsArr[13]=='4' ? true : false">
                                <label class="custom-control-label" for="question__14__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[13]" :getQuestionFiles="getQuestionFiles" />   
                            </div>                        
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <!-- BUTTON SAVE -->
            <b-button 
                class="btn-save btn"               
                id="saveInfo"    
                v-b-tooltip.hover.bottom.v-dark
                title="Guardar Información"
                variant="outline-primary"
                @click="saveInformation" 
            >            
                <feather-icon class="btn-icon"
                    icon="SaveIcon"                             
                />
            </b-button>
            
            <!-- BUTTON SEND -->
            <b-button 
                class="btn-send btn btn-lg"
                @click="sendInformation"
                id="sendInfo"    
            >                      
                Enviar Información para la evaluación
                <feather-icon size="1x"
                    icon="SendIcon"                             
                />                          
            </b-button>    
            
        </b-form>
    </div>
    
</template>
<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import ButtonUpload from '@core/components/button-upload/ButtonUpload.vue'
    import CounterTime from '@core/components/counter-time/CounterTime.vue'
    import JsonExcel from '../../../reporterias/download-excel';
    import WorkersImportFile from './WorkersImportFile.vue'
    import { States } from '@core/utils/data'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, positive, alphaNum, digits } from '@validations'
    import { onUnmounted, ref } from '@vue/composition-api'
    import { VBTooltip } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import axios from '@axios'
    import store from '@/store'
    import router from '@/router'
    import Swal from 'sweetalert2'
    import vSelect from 'vue-select'
    import moment from 'moment'
    import formStoreModule from '../formStoreModule'
    
    export default {
        components: {
            ButtonUpload,
            CounterTime,
            WorkersImportFile,
            
            ValidationProvider, 
            ValidationObserver,
            JsonExcel,
            vSelect
        },
        directives: {
            'b-tooltip': VBTooltip,
            Ripple
        },
        data(){
            return {
                States,
                
                required,
                email,
                alphaNum,
                positive,
                digits,
                
                isLoading: false,
                isSubmitDisabled: true,

                questionFiles: [],
                questionsArr: [
                    null,      
                    null,  
                    null,
                    null,  
                    null,  
                    null,  
                    null,  
                    null,  
                    null,  
                    null,  
                    null,  
                    null,  
                    null,  
                    null,    
                ],            
                form_id:  router.currentRoute.params.id,
                stateId: null,
                codeArr: [
                    null,      
                    null,  
                    null,
                    null,  
                    null,  
                    null,  
                    null,  
                    null,  
                    null,  
                    null,  
                    null,  
                    null,  
                    null,  
                    null, 
                    null,
                    null,
                    null,
                ],
                
                // CounterTime
                ct_state_id: null,
                ct_deadline: new Date(),
                ct_isLoaded: false,
                formData: {},

                earrings: [],
                previousFormDate: null,
                                
                // Table
                totalRows: 1,
                currentPage: 1,
                stickyHeader: true,
                perPage: 10,
                pageOptions: [5, 10, 15, 20],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                workers: [],
                fields: [],
                
                // Modal Worker
                okTitle: 'ACTUALIZAR',
                titleModal: 'ACTUALIZAR REGISTRO',
                actionModal: '',
                dataWorker: {
                    fullName: '',
                    dni: '',
                    role: '',
                    salary: '',
                    category: '',
                    daily_wage: '',
                    phone: '',
                    email: '',
                    worker_state: {},
                    termination_reason: {}
                },
                
                //business areas
                optionsPositons: [],
                positionsData: [],
                business_area_id: null,
                tariff_id: null,
                salaryTariff: null,
                
                RUBRO_MINA: 1,
                
                optionsCategory: ['EMPLEADO', 'OBRERO'],
                optionsWorkerStates: [],
                optionsTerminationReasons: []
            }
        },
        computed: {
            sortOptions() {
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({ text: f.label, value: f.key }))
            },
        },
        async beforeMount(){
            await this.getFormInformation()
            await this.getAnswerInformation()
            await this.getQuestionFiles()
            await this.getFileQuestion()
        },
        methods: {            
            customToast (success, message) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: message,
                        icon: success ? 'CheckIcon' : 'AlertTriangleIcon',
                        variant: success ? 'success' : 'danger'
                    }
                })
            },
            changePosition (position) {
                this.salaryTariff = ''
                if (position) {
                    const positionFound = this.positionsData.find( item => item.name === position) 
                    this.salaryTariff = positionFound ? positionFound.salary : ''
                }
            },
            async getFormInformation(){
                const { data } = await axios.get(`/provider/form/${this.form_id}/edit`);
                
                if (data) 
                {
                    const { earrings, previousFormDate, formData } = data;

                    this.formData = formData;
                    this.earrings = earrings;
                    this.previousFormDate = previousFormDate;

                    this.business_area_id = formData.provider.business_area_id;
                    this.tariff_id = formData.tariff_id;
                    this.stateId = formData.state_id;

                    this.ct_deadline = moment(formData.deadline, 'YYYY-MM-DD').add(1, 'days').toDate();
                    this.ct_isLoaded = true;
                    
                    await this.getWorkers();
                    await this.getWorkerStates();
                    await this.getTerminationReasons();
                }
            },
            async getAnswerInformation(){
                let response = await axios.get(`/provider/form-a/${this.form_id}/edit`)
                if(response.data){
                    let cont = 0                                     
                    for (const key in response.data) { 
                        this.questionsArr[cont] = response.data[key]                    
                        cont++
                    }                           
                }
            },
            async getQuestionFiles(){
                let response = await axios.get(`/provider/form/${this.form_id}/question/files`)
                this.questionFiles = response.data.questionFiles
            },
            async getFileQuestion(){
                let response = await axios.get('/form/questions')
                this.codeArr = response.data;
                
                if (this.earrings.length)
                {
                    const questions = this.earrings.map(item => `- ${item.question}`);

                    this.codeArr[16].title = `LISTADO DE PENDIENTES DEL FORMULARIO (${this.previousFormDate})`;
                    this.codeArr[16].description = questions.join('<br/>');
                }
            },
            async validateFiles (){
                let noFiles = []
                this.questionFiles.map((item, index) => {
                    // (this.questionsArr[index] == 1 || this.questionsArr[index] == 2) &&
                    if ((this.questionsArr[index] == 1 || this.questionsArr[index] == 2 || this.questionsArr[index] == 4) && !item.uploaded_files_count){
                        noFiles.push(item.id)
                    }
                })

                return noFiles
            },
            openSidebar () {
                
                Swal.fire({
                    title: 'Descargue la última plantilla con los nuevos cambios',
                    text: 'Omitir el mensaje si ya está en uso.',
                    icon: 'info',
                    showConfirmButton: true,
                    timer: 5000,
                    customClass: {
                        confirmButton: 'btn btn-info',
                    },
                    buttonsStyling: false
                }).then( _=> {
                    
                    this.$refs['refPlantillaModal'].hide()
                    this.isAddNewUserSidebarActive = true
                })   
            },
            async sendInformation () {   
                        
                if (this.tariff_id) 
                {
                    const { data: { workers_minimum } } = await axios.post(`/provider/form/${this.form_id}/workers-minimum`)
                    
                    if (this.workers.length < workers_minimum) 
                    {
                        const title = !this.workers.length ? 'Planilla Vacía': 'Planilla Incompleta'
                        const complement = workers_minimum === 1 ? '.' : '. Siendo como mínimo el 50% de la planilla del mes anterior.'

                        Swal.fire({
                            title,
                            text: `Debe tener, al menos, ${workers_minimum} empleado(s) registrado(s) en su planilla del mes${complement}`,
                            icon: 'warning',
                            showConfirmButton: false,
                            timer: 10000,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false
                        })

                        return
                    }
                                    
                    const incompleteWorkers = this.workers.some(worker => !worker.role || !worker.daily_wage)

                    if (incompleteWorkers) 
                    {
                        Swal.fire({
                            title: 'Planilla Incompleta',
                            text: 'Todos los trabajadores deben tener el cargo/puesto y el jornal diario. Completar los datos.',
                            icon: 'warning',
                            showConfirmButton: false,
                            timer: 5000,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false
                        })

                        return
                    }
                    
                    const workersFilesRequired = this.workers.filter( worker => worker.tariff_compliance != 1)
                    const filesUploaded = this.questionFiles[14].uploaded_files_count
                    
                    if (this.tariff_id && workersFilesRequired.length > filesUploaded) 
                    {
                        Swal.fire({
                            title: 'Falta Boletas de Pago en Planilla',
                            html: `Debe cargar al menos ${workersFilesRequired.length} archivo(s) en la sección <strong>Cargar Boletas</strong>`,
                            icon: 'warning',
                            showConfirmButton: false,
                            timer: 5000,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false
                        })

                        return
                    }           
                }                
                
                const validateFilesRes = await this.validateFiles()

                if (validateFilesRes.length) 
                {
                    Swal.fire({
                        title: 'Error',
                        text: `Ups, La(s) pregunta(s) ${validateFilesRes.join(', ')} debe(n) tener al menos un archivo cargado para enviar la información a revisión. Verifique la información cargada.`,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 6500,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: false
                    })

                    return
                }

                if (this.earrings.length && !this.questionFiles[16].uploaded_files_count)
                {
                    Swal.fire({
                        title: 'Error',
                        text: `El listado de pendientes debe tener al menos un archivo cargado para enviar la información a revisión. Verifique la información cargada.`,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 4500,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: false
                    })

                    return
                }

                let dataForm = {
                    question__1: this.questionsArr[0],
                    question__2: this.questionsArr[1],
                    question__3: this.questionsArr[2],
                    question__4: this.questionsArr[3],
                    question__5: this.questionsArr[4],
                    question__6: this.questionsArr[5],
                    question__7: this.questionsArr[6],
                    question__8: this.questionsArr[7],
                    question__9: this.questionsArr[8],
                    question__10: this.questionsArr[9],
                    question__11: this.questionsArr[10],
                    question__12: this.questionsArr[11],
                    question__13: this.questionsArr[12],
                    question__14: this.questionsArr[13],
                }
                
                Swal.fire({
                    title: '¿Está seguro de enviar ésta información?',
                    text: "¡Asegúrese de haber guardado antes de enviar!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, enviar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(result => {

                    if (result.value) 
                    {                    
                        axios.put(`/provider/form/${this.form_id}/update`, dataForm)
                        .then( response => {
                            
                            if(response.data.state) {

                                Swal.fire({
                                    title: 'Se ha enviado exitosamente!',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    customClass: {
                                        confirmButton: 'btn btn-success',
                                    },
                                    buttonsStyling: false,
                                })

                                router.push({name: 'provider-forms'})   
                                
                            } else {

                                Swal.fire({
                                    title: 'Error',
                                    text: 'Ups, hubo un error al enviar la información!',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: false,
                                })

                            }
                        })
                    }

                })
                
            },
            async saveInformation(){
                        
                let dataForm = {
                    question__1: this.questionsArr[0],
                    question__2: this.questionsArr[1],
                    question__3: this.questionsArr[2],
                    question__4: this.questionsArr[3],
                    question__5: this.questionsArr[4],
                    question__6: this.questionsArr[5],
                    question__7: this.questionsArr[6],
                    question__8: this.questionsArr[7],
                    question__9: this.questionsArr[8],
                    question__10: this.questionsArr[9],
                    question__11: this.questionsArr[10],
                    question__12: this.questionsArr[11],
                    question__13: this.questionsArr[12],
                    question__14: this.questionsArr[13],
                }
                
                await axios.put(`/provider/form-a/${this.form_id}/update`, dataForm)
                .then( response => {
                                    
                    if(response.data.state) {
                        Swal.fire({
                            title: 'Se ha guardado exitosamente!',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1200,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        })    
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: 'Ups, hubo un error al guardar la información!',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })   
                    }
                    
                })
                
            },
            async getPositions () {
                this.optionsPositons = []
                this.positionsData = []
                if (this.tariff_id) {
                    const result = await axios.get(`/positions/select?tariff_id=${this.tariff_id}`)
                    if (result && result.data) {
                        this.positionsData = result.data
                        Array.from(new Set([...result.data.map( item => item.name)])).map( position => {
                            this.optionsPositons.push(position)
                        })   
                    }
                }
            },
            async getWorkers () {                
                await this.getPositions()
                const result = await axios.get(`/form/${this.form_id}/workers`)
                if (result && result.data) {                        
                    this.workers = result.data 
                    
                    if (this.tariff_id) {
                        this.workers = []
                        
                        result.data.map( item => {                                
                            let position = this.optionsPositons.find( position => position == item.role)
                            
                            this.workers.push({
                                id: item.id,
                                fullName: item.fullName,
                                dni: item.dni,
                                role: position || null,
                                category: item.category,
                                salary: item.salary ? Number(item.salary).toFixed(2) : null,
                                daily_wage: item.daily_wage ? Number(item.daily_wage).toFixed(2) : null,
                                tariff_compliance: item.tariff_compliance,
                                phone: item.phone,                            
                                email: item.email,
                                worker_state: item.worker_state,
                                termination_reason: item.termination_reason
                            })
                        })
                    }                        
                }
            },
            async getWorkerStates () {
                this.optionsWorkerStates = [];

                const { data } = await axios.get('/selects/worker-states');                 
                if (data.success) {
                    this.optionsWorkerStates = [ ...data.workerStates ];
                }
            },
            async getTerminationReasons() {
                this.optionsTerminationReasons = [];

                const { data } = await axios.get('/selects/termination-reasons');
                if (data.success) {
                    this.optionsTerminationReasons = [ ...data.terminationReasons ];
                }
            },
            viewWorkers () {                
                if (this.form_id) {                   
                    this.totalRows = this.workers.length                    
                    this.currentPage = 1
                    this.sortBy = ''
                    this.sortDesc = false
                    this.sortDirection = 'asc'
                    this.filter = null
                    
                    const styleColum = {
                        thClass: 'text-center',
                        tdClass: 'text-center'
                    }
                    this.fields = [{
                        key: 'id', 
                        label: '#',
                        sortable: true,
                        tdClass: styleColum.tdClass
                    }, {
                        key: 'fullName', 
                        label: 'Nombre completo',
                        sortable: true,                    
                        thClass: styleColum.thClass
                    }, {
                        key: 'dni', 
                        label: 'DNI',
                        sortable: true,
                        ...styleColum
                    }, {
                        key: 'role', 
                        label: 'Puesto/Cargo/Rol',
                        sortable: true,
                        ...styleColum
                    }, {
                        key: 'category', 
                        label: 'Categoría',
                        sortable: true,
                        ...styleColum
                    }, {
                        key: 'salary', 
                        label: 'Sueldo',
                        sortable: true,
                        thClass: styleColum.thClass,
                        tdClass: 'text-right'
                    }, {
                        key: 'daily_wage', 
                        label: 'Jornal Diario',
                        sortable: true,
                        thClass: styleColum.thClass,
                        tdClass: 'text-right'
                    }, {
                        key: 'phone', 
                        label: 'Celular',
                        sortable: true,
                        thClass: styleColum.thClass,
                    }, {
                        key: 'email', 
                        label: 'Correo electrónico',
                        sortable: true,
                        thClass: styleColum.thClass,
                    }, {
                        key: 'worker_state', 
                        label: 'Estado',
                        sortable: true,
                        ...styleColum
                    }]
                    
                    if (this.tariff_id) {
                        const tariff_col = {
                            key: 'tariff_compliance', 
                            label: 'Análisis Tarifario',
                            sortable: false,
                            ...styleColum
                        }
                        this.fields.splice(this.fields.length - 1, 0, tariff_col);
                    }
                                                            
                    const { EN_CURSO, POR_REALIZAR } = this.States                    
                    if ([EN_CURSO, POR_REALIZAR].includes(this.stateId)) {
                        this.fields.push({
                            key: 'actions', 
                            label: 'Opciones',
                            sortable: false,
                            ...styleColum
                        })
                    }                    
                    this.$refs['refPlantillaModal'].show()
                }
            },
            removeCurrencyFormat (item, currency = 'PEN') {
                if (item) {
                    if (currency === 'PEN') item = item.replace('S/ ', '');

                    if (currency === 'USD') item = item.replace('$ ', '');

                    item = item.replaceAll(',', '');

                    return item
                }

                return '-'
            },
            downloadWorkers () {
                let array = [];

                for (let a = 0; a < this.workers.length; a++)
                {
                    let objBody = {
                        'ITEM': (a + 1),
                        'NOMBRE COMPLETO': this.workers[a]['fullName'],
                        'DNI': this.workers[a]['dni'],
                        'PUESTO/CARGO/ROL': this.workers[a]['role'],
                        'CATEGORÍA': this.workers[a]['category'],
                        'SUELDO': this.removeCurrencyFormat(this.workers[a]['salary']),
                        'JORNAL DIARIO': this.removeCurrencyFormat(this.workers[a]['daily_wage']),
                        'CELULAR': this.workers[a]['phone'],
                        'CORREO ELECTRÓNICO': this.workers[a]['email'],
                        'ESTADO': this.workers[a]['worker_state'] ? this.workers[a]['worker_state']['name'] : null,
                        'MOTIVO CESE': this.workers[a]['termination_reason'] ? this.workers[a]['termination_reason']['name'] : null
                    };

                    array.push(objBody);
                }

                return array;
            },
            onFiltered (filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            deleteWorker (id){
                Swal.fire({
                    title: '¿Estás seguro de eliminar al empleado(a) de la planilla?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, eliminar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {

                        this.$store.dispatch('app-provider-form/deleteWorker', { id })
                            .then( response => {

                                const result = this.workers.filter(worker => worker.id != id)
                                this.workers = result
                                this.customToast(true, response.data.message)
                            })
                            .catch( _=> {
                                const message = 'Error al eliminar al empleado(a)'
                                this.customToast(false, message)
                            })
                    }
                })
            },
            validateRequiredFields () {
                if (this.dataWorker.fullName && 
                    this.dataWorker.dni && 
                    this.dataWorker.role && 
                    this.dataWorker.category && 
                    this.dataWorker.salary && 
                    this.dataWorker.daily_wage &&
                    this.dataWorker.worker_state &&
                    this.dataWorker.worker_state.id) {

                    this.isSubmitDisabled = false
                } else {
                    this.isSubmitDisabled = true                   
                }
            },
            validateFullname () {                
                const fullName = this.dataWorker.fullName.trim()
                const words = fullName.split(/\s+/)
                
                if (words.length < 2) {
                    const error = 'El Nombre Completo debe tener al menos un nombre y un apellido.'
                    this.customToast(false, error)
                    return false;
                }

                const regex = /^[\p{L}\s]+$/u;
                if (!regex.test(fullName)) {
                    const error = 'El Nombre Completo solo debe ser texto.'
                    this.customToast(false, error)
                    return false;
                }

                return true;
            },
            validateTerminationReason () {
                if (this.dataWorker.worker_state.name === 'CESADO' && !this.dataWorker.termination_reason) {
                    const error = 'Debe seleccionar el Motivo de Cese.'
                    this.customToast(false, error)
                    return false;
                }

                return true;
            },
            async addWorker () {
                this.salaryTariff = null
                this.actionModal = 'create'
                this.okTitle = 'AGREGAR'
                this.titleModal = 'AGREGAR REGISTRO'
                this.dataWorker = {
                    form_id: this.form_id
                }
                
                this.isLoading = false;
                this.isSubmitDisabled = true;
                this.$refs['refWorkerModal'].show()
            },
            editWorker (data, index){
                const positionFound = this.positionsData.find( item => item.name === data.role) 
                this.salaryTariff = positionFound ? positionFound.salary : ''
                
                this.actionModal = 'update'
                this.okTitle = 'ACTUALIZAR'
                this.titleModal = 'ACTUALIZAR REGISTRO'
                this.dataWorker.index = index
                this.dataWorker.form_id = this.form_id
                this.dataWorker.id = data.id
                this.dataWorker.fullName = data.fullName
                this.dataWorker.dni = data.dni
                this.dataWorker.role = data.role
                this.dataWorker.category = data.category
                this.dataWorker.salary = data.salary
                this.dataWorker.daily_wage = data.daily_wage
                this.dataWorker.phone = data.phone
                this.dataWorker.email = data.email
                this.dataWorker.worker_state = data.worker_state
                this.dataWorker.termination_reason = data.termination_reason
                
                this.isLoading = false;
                this.isSubmitDisabled = true;
                this.$refs['refWorkerModal'].show()
            },
            resetModal() {
              
              /*   this.dataWorker = {
                    form_id: this.form_id,
                    fullName: '',
                    role: '',
                    category: '',
                    salary: '',
                    daily_wage: '',
                    phone: '',
                    email: ''
                };   */          
            },
            async refetchData () {
                await this.getWorkers()
            },
            async onSubmit() {
                this.$refs.workerForm.validate().then(async success => {
                    if (success) {
                        if (!this.validateFullname()) return;
                        if (!this.validateTerminationReason()) return;

                        let response = null
                        this.isLoading = true
                        if (this.actionModal == 'create') response = await axios.post(`/worker/create`, this.dataWorker)
                        if (this.actionModal == 'update') response = await axios.put(`/worker/${this.dataWorker.id}/update`, this.dataWorker)
                    
                        this.isLoading = false
                        if (response.data.res == 200){
                            await this.getWorkers()
                            this.$refs['workersTable'].refresh()
                            this.$refs['refWorkerModal'].hide()
                            this.customToast(true, response.data.message)
                            return
                        } 
                        this.customToast(false, response.data.message)
                    }
                })
            },
        },
        setup () {

            const isAddNewUserSidebarActive = ref(false)
            
            const USER_APP_STORE_MODULE_NAME = 'app-provider-form'
            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, formStoreModule)

            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
            })

            return {
                isAddNewUserSidebarActive,    
            }
        }
    }

</script>

<style scoped>

    .content-table {
        border-collapse: collapse;
        margin: 25px 0;
        min-width: 400px;
        border-radius: 10px 10px;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }

    .content-table thead tr th {
        font-weight: bold;
        font-size: 20px;
        padding: 45px 0px;
    }

    .content-table td {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .form_question{
        font-size: 1rem;
        margin-bottom: 0px;
    }
    
    .custom-control-label{
        font-size: .8rem;
    }
    
    .btn-send {
        outline: none;
        display: block;
        border: solid 1px #d8a400 !important;
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        width: 100%;
        border-radius: 35px;
        color: #d8a400 !important;
        background: inherit !important;
        cursor: pointer;
        transition: all 0.3s linear;
        text-transform: uppercase;
        padding-top: 15px;
        padding-bottom: 15px;
        box-shadow: 0 .25rem .25rem rgba(0, 0, 0, 0.15);
    }
    
    .btn-send:hover,
    .btn-send:focus {
        background: #d8a400 !important;
        color:  rgba(255, 255, 255, 0.842) !important;
    }
    
    .btn-send:active {
        color: #dfbb45;
    }

    .btn-save {        
        outline: none;
        position: fixed;
        width: 55px;
        height: 55px;
        line-height: 55px;        
        bottom: 90px;
        right: 60px;
        color: rgba(255, 255, 255, 0.842);
        background: #d8a400;
        transition: all 0.3s linear;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
        cursor: pointer; 
    }
    
    .btn-save:hover,
    .btn-save:focus {
        color:  rgba(255, 255, 255, 0.842);
    }
    
    .btn-save:active {
        background: #0020d8;
    }
    
    .btn-save .btn-icon{
        position: fixed;
        width: 30px;
        height: 40px;
        bottom: 95px;
        right: 72px;
    }
    
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>